import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { mergeDeepRight } from 'ramda'
import typography from './typography'
import { softShadows } from './shadows'
import brand from '../lib/util/brand'

const themeConfig = brand.theme
const { green, orange } = colors

const defaultTheme = createMuiTheme(themeConfig)

const baseConfig = {
  direction: 'ltr',
  typography,
  shadows: softShadows,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: defaultTheme.spacing(2),
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: defaultTheme.palette.background.paper,
      },
    },
    MuiTab: {
      root: {
        padding: 8,
        minHeight: 32,
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: 'auto',
        },
        marginRight: 24,
        opacity: 0.8,
        color: defaultTheme.palette.secondary.main,
        '&$selected': {
          color: defaultTheme.palette.secondary.main,
        },
        '&:hover': {
          opacity: 1,
          color: defaultTheme.palette.secondary.main,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: 32,
        position: 'relative',
        // Extend tabs to the side of the screen at small sizes.
        // TODO: This does not actually deserve to be global.
        [defaultTheme.breakpoints.down('sm')]: {
          marginLeft: defaultTheme.spacing(-3),
          marginRight: defaultTheme.spacing(-3),
          borderBottom: `1px solid ${defaultTheme.palette.grey['200']}`,
        },
        [defaultTheme.breakpoints.down('xs')]: {
          marginLeft: defaultTheme.spacing(-2),
          marginRight: defaultTheme.spacing(-2),
        },
        '&::after': {
          content: '" "',
          position: 'absolute',
          right: 0,
          background: 'linear-gradient(270deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%)',
          height: '100%',
          width: defaultTheme.spacing(4),
        },
      },
    },
    MuiListSubheader: {
      root: {
        ...typography.overline,
        lineHeight: 1,
        height: 'auto',
        color: defaultTheme.palette.grey['600'],
        marginTop: 16,
        marginBottom: 8,
      },
    },
  },
  palette: {
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
      contrastText: '#fff',
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700],
      contrastText: '#fff',
    },
  },
  props: {
    MuiTextField: {
      fullWidth: true,
      InputLabelProps: {
        shrink: true,
      },
      SelectProps: {
        displayEmpty: true,
      },
      variant: 'outlined',
      margin: 'normal',
    },
    MuiFormControl: {
      margin: 'normal',
    },
    MuiSelect: {
      fullWidth: true,
      variant: 'outlined',
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      variant: 'contained',
      disableElevation: true,
    },
    MuiButtonGroup: {
      variant: 'contained',
      disableElevation: true,
    },
    MuiCard: {
      variant: 'outlined',
    },
  },
}

export function createTheme(settings = {}) {
  let theme = createMuiTheme(mergeDeepRight(baseConfig, themeConfig, { direction: settings.direction }))

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
